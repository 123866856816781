import { createMuiTheme } from '@material-ui/core/styles';

export const colorPalette = {
  mahogany: '#6f250f',
  orangeRed: '#d47c5e',
  black: 'rgba(0, 0, 0, 0.87)',
  lightLink: 'rgba(255, 176, 149, 1)',
  white: '#fafafa',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Josefin Slab', 'Open Sans', 'Roboto'],
    button: {
      fontWeight: 600,
      textTransform: 'none',
      letterSpacing: .75,
    },
    h1:{
      fontWeight: 600
    },
    h2:{
      fontWeight: 600
    },
    h3:{
      fontWeight: 600
    },
    h4:{
      fontWeight: 600
    },
    h5:{
      fontWeight: 600
    },
    h6:{
      fontWeight: 600
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: colorPalette.mahogany,
    },
    secondary: {
      main: colorPalette.orangeRed,
    },
  },
})

export default theme;